<template>
    <b-modal
      :no-close-on-backdrop="true"
      id="movement-modal"
      hide-header
      hide-footer
      centered
      size="lg"
      @hidden="onHidden"
      @show="onShow"
    >
      <div class="header">
        <div class="header-content">
          <p>Movimentações de conta</p>
          <Close class="close-icon" @click="closeModal" />
        </div>
      </div>
      <div class="body">
        <div class="row">
          <div class="col-4">
            <b-form-group>
              <label for="field_date" id="title-styles">
                Data do atendimento
              </label>
              <date-picker
                class="full"
                v-model="form.date"
                format="DD/MM/YYYY"
                placeholder="DD/MM/AAAA"
                :clearable="false"
                :lang="langDatePicker"
                :disabled="disabled()"
              ></date-picker>
              <div v-if="validated && !form.date" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-form-group>
            </div>
            <div class="col-8">
              <b-form-group>
                <Select 
                  label="Método da operação" 
                  v-model="form.method"
                  :options="methods"
                  :disabled="disabled()"
                />
                <div v-if="validated && !form.method" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-form-group>
              
            </div>
            <div class="col-12">
              <b-form-group>
                <Select 
                  label="Conta origem" 
                  v-model="form.origin_id"
                  :options="bankAccounts"
                  :disabled="disabled()"
                  @input="updateOriginValue"
                />
                <div v-if="validated && !form.origin_id" class="custom-invalid-feedback">Campo obrigatório</div>
                <div v-if="validated && form.origin_id && form.destination_id && form.origin_id === form.destination_id" class="custom-invalid-feedback">A conta de origem e destino devem ser diferentes</div>
              </b-form-group>
            </div>

            <div class="col-4">
              <b-form-group>
                <label for="field_amount" id="title-styles">Saldo atual conta origem</label>
                <money
                  class="form-control"
                  :class="{ negative: form.origin_current_value < 0 }"
                  :value="form.origin_current_value"
                  :disabled="true"
                />                
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group>
                <label for="field_amount" id="title-styles">Valor movimentação</label>
                <money
                  class="form-control"
                  :class="{ negative: form.value < 0 }"
                  v-model="form.value"
                  :disabled="disabled()"
                />
                <div v-if="validated && !form.value" class="custom-invalid-feedback">Campo obrigatório</div>
                <div v-else-if="validated && form.value < 0" class="custom-invalid-feedback">O valor deve ser maior que 0</div>
                <div v-else-if="validated && form.origin_next_value < 0" class="custom-invalid-feedback">O valor deve ser menor ou igual ao saldo da conta</div>
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group>
                <label for="field_amount" id="title-styles">Saldo final conta origem</label>
                <money
                  class="form-control"
                  :class="{ negative: form.origin_next_value < 0 }"
                  :value="form.origin_next_value"
                  :disabled="true"
                />
              </b-form-group>
            </div>

            <div class="col-4">
              <b-form-group>
                <Select 
                  label="Conta destino" 
                  v-model="form.destination_id"
                  :options="bankAccounts.filter(bankAccount => bankAccount.id !== form.origin_id)"
                  :disabled="disabled()"
                  @input="updateDestinationValue"
                />
                <div v-if="validated && !form.destination_id" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group>
                <label for="field_amount" id="title-styles">Saldo atual conta destino</label>
                <money
                  class="form-control"
                  :class="{ negative: form.destination_current_value < 0 }"
                  :value="form.destination_current_value"
                  :disabled="true"
                />
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group>
                <label for="field_amount" id="title-styles">Saldo final conta destino</label>
                <money
                  class="form-control"
                  :class="{ negative: form.destination_next_value < 0 }"
                  :value="form.destination_next_value"
                  :disabled="true"
                />
              </b-form-group>
            </div>

            <hr />

            <div class="col-12">
              <b-form-group>
                <label for="field_description" id="title-styles">
                  Observações
                  <span class="help">(opcional)</span>
                </label>
                <b-form-input
                  autocomplete="off"
                  v-model="form.observations"
                  placeholder="Observações"
                />
              </b-form-group>
            </div>
        </div>
        
     
        <div class="end-footer end">
          <b-button
            v-can="'FiConRec2'"
            class="wh-button"
            variant="primary"
            :disabled="loading"
            @click="!form?.id ? createMovement() : updateMovement()"
          >
            {{ loading ? 'Aguarde...' : !form.id ? 'Concluir operação' : 'Salvar' }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </template>
  <script>
  import api from '../api'
  import { getCurrentClinic } from '@/utils/localStorageManager'
  
  export default {
    components: {
      Close: () => import('@/assets/icons/close.svg'), 
      Select:() => import('@/components/Select'),
    },
    props: {
      movement: Object,
      bankAccounts: Array,
    },
    data() {
      return {
        clinic: getCurrentClinic(),
        validated: false,
        loading: false,
        form: this.getDefaultForm(),
        methods: [
          { label: 'Selecionar', value: null },
          { label: 'PIX', value: 'PIX' },
          { label: 'TED', value: 'TED' },
        ],
        langDatePicker: {
          formatLocale: {
            weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
          }
        },
      }
    },
    methods: {
      disabled() {
        return !!this.form?.id
      },
      onShow() {
        this.form = this.movement ?? this.getDefaultForm()
        if (this.movement?.id) {
          this.form.date = new Date(this.movement.date)
          this.form.origin_current_value = this.movement.origin_current_value
          this.form.origin_next_value = this.movement.origin_next_value
          this.form.destination_current_value = this.movement.destination_current_value
          this.form.destination_next_value = this.movement.destination_next_value
        }
      },
      onHidden() {
        this.clearForm()
        this.$emit('hidden')
      },
      getDefaultForm(){
        return {
          id: null,
          date: new Date(),
          method: null, 
          origin_id: null,
          origin_current_value: 0,
          value: 0,
          origin_next_value: 0,
          destination_id: null,
          destination_current_value: 0,
          destination_next_value: 0,
          observations: null,
        }
      },
      closeModal() {
        this.$bvModal.hide('movement-modal')
      },
      isValidForm() {
        this.validated = true
        if(this.form.origin_next_value < 0){
          this.$toast.warning("O valor da movimentação é maior que o saldo disponivel em conta")
          return false
        } 
        return this.form.method &&
          this.form.origin_id &&
          this.form.destination_id &&
          this.form.origin_id !== this.form.destination_id &&
          this.form.value > 0
      },
      clearForm() {
        this.validated = false
        this.form = this.getDefaultForm()
      },
      createMovement() {
        if (!this.isValidForm()) return
        this.loading = true
        api.createMovement(this.form)
        .then(() => {
          this.$toast.success('Movimentação criada com sucesso')
          this.closeModal()
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => (this.loading = false))
      },
      updateMovement() {
        if (!this.isValidForm()) return
        this.loading = true
        api.updateMovement(this.form.id, { observations: this.form.observations })
        .then(() => {
          this.$toast.success('Observações atualizadas com sucesso')
          this.closeModal()
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => (this.loading = false))
      },      
      updateOriginValue() {
        const bankAccount = this.bankAccounts.find(bankAccount => bankAccount.id === this.form.origin_id)
        this.form.origin_current_value = bankAccount?.account_balance || 0
        this.form.origin_next_value = (bankAccount?.account_balance || 0) - (this.form.value || 0)
      },
      updateDestinationValue() {
        const bankAccount = this.bankAccounts.find(bankAccount => bankAccount.id === this.form.destination_id)
        this.form.destination_current_value = bankAccount?.account_balance || 0
        this.form.destination_next_value = (bankAccount?.account_balance || 0) + (this.form.value || 0)
      },
    },
    watch: {
      'form.value': function() {
        this.updateOriginValue()
        this.updateDestinationValue()
      }
    }
  }
  </script>
  <style lang="scss">
  #movement-modal {
    .modal-content {
      .modal-body {
        padding: 0;
  
        .header {
          border-bottom: 1px solid var(--neutral-200);
          .header-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 16px 24px;
            p {
              font-weight: 600;
              font-size: 16px;
              color: var(--type-active);
            }
            .close-icon {
              fill: var(--neutral-500);
              height: 24px;
              width: 24px;
            }
          }
        }
        .body {
          padding: 20px;
          .end-footer-pre-total {
            display: flex;
            gap: 10px;
            justify-content: flex-end;
          }
          .end-footer {
            display: flex;
            gap: 10px;
  
            &.space {
              justify-content: space-between;
            }
  
            &.end {
              justify-content: flex-end;
            }
  
            .danger {
              color: var(--states-error);
            }
          }
          .items-title {
            font-weight: 700;
            font-size: 16px;
            color: var(--dark-blue);
          }
          .negative {
            color: var(--states-error);
          }
        }

      }
    }
  }
  </style>
  